import React, { Component } from "react";
import { isEmpty } from "../shared";
import AnalysisComponent from "./AnalysisComponent/AnalysisComponent";
import { MOCK_EMAILS, MOCK_EMAIL_HOME } from "../utils";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import LargeLoadingSpinner from "./LargeLoadingSpinner";

class FollowupEmail extends Component {

  render() {
    const { handleGenerateEmail, isLoadingEmail, isEmailError} = this.props;
    const {followupEmail} = this.props;
    // const followupEmail = MOCK_EMAILS;
    return(
      <div>
        <div class="flex items-center justify-center text-xs whitespace-pre-line">
          {isEmailError && <div class="text-sm pt-12">Error generating email. Please contact amrit@helloxylo.com for assistance.</div>}
          {isEmpty(followupEmail) && !isEmailError && 
          <div class="pt-12">
            {isLoadingEmail && <LargeLoadingSpinner />}
            {!isLoadingEmail && <button class={`rounded-md bg-indigo-600 text-white font-bold px-4 py-2 text-sm flex justify-center items-center opacity-35"}`} onClick={() => handleGenerateEmail()}>
                      <span>Generate Email<EnvelopeIcon class="inline ml-2 h-4 w-4" /></span>
              </button>}
            </div>}
          {!isEmpty(followupEmail) && <AnalysisComponent text={ `<div>${followupEmail}</div>`} isEmail={true} />}
        </div>
      
      </div>
    
    )
  }
}

export default FollowupEmail;
